import React from 'react';
import styled from 'styled-components';

const Naehrwerte = props => {
    const TableWrapper = styled.div`
        background-color: white;
        border: 1px solid #3155a1;
        border-radius: 10px;
        overflow: hidden;
        max-width: 400px;
        margin: 0 auto;

        ${({ threeColumns }) => threeColumns && `max-width: 700px;`};
    `;

    const Table = styled.table`
        border-collapse: collapse;
        text-align: left;
        width: 100%;
    `;

    const TD = styled.td`
        margin: 0;
        padding: 10px;
    `;

    const FirstTd = styled(TD)`
      width: 40%;
    `;

    const ValueTd = styled(TD)`
      text-align: right;
      width: 60%;

      ${({ threeColumns }) => threeColumns && `width: 20%;`};
    `;

    const Tr = styled.tr`
        border-top: 1px solid #3155a1;
    `;

    const Styledtr = styled(Tr)`
        background: #d6ddef;
    `;

    const StyledtrBold = styled(Tr)`
        background: #d6ddef;
        font-weight: 700;
    `;

    const Trpadding = styled(Styledtr)`
        td {
          padding: 10px 10px 10px 20px;
        }
    `;

    const Text = styled.p`
        margin: 10px;
    `;

    const Title = styled.h3`
        margin: 0;
        color: white !important;
        font-weight: normal;
    `;

    const THead = styled.tr`
      background: #3155a1;
      padding: 10px;
    `;

    return (
        <TableWrapper threeColumns={props.values.Columnheader}>
            <Table>
                <THead>
                    <TD colSpan={props.values.Columnheader === null ? 2 : 4}>
                        <Title>{props.headline}</Title>
                    </TD>
                </THead>
                {props.values.Columnheader && <StyledtrBold>
                    <FirstTd></FirstTd>
                    <ValueTd threeColumns={props.values.Columnheader}>{props.values.Columnheader.col1}</ValueTd>
                    <ValueTd threeColumns={props.values.Columnheader}>{props.values.Columnheader.col2}</ValueTd>
                    <ValueTd threeColumns={props.values.Columnheader}>{props.values.Columnheader.col3}</ValueTd>
                </StyledtrBold>}
                <Styledtr>
                    <FirstTd>{props.values.Brennwert.Brennwerttitle}</FirstTd>
                    <ValueTd threeColumns={props.values.Columnheader}>{props.values.Brennwert.Brennwertgramm}</ValueTd>
                    {props.values.Brennwert.Brennwertgramm1 && (<ValueTd threeColumns={props.values.Columnheader}>{props.values.Brennwert.Brennwertgramm1}</ValueTd>)}
                    {props.values.Brennwert.Brennwertgramm2 && (<ValueTd threeColumns={props.values.Columnheader}>{props.values.Brennwert.Brennwertgramm2}</ValueTd>)}
                </Styledtr>
                <Tr>
                    <FirstTd>{props.values.Fett.Fetttitle}</FirstTd>
                    <ValueTd threeColumns={props.values.Columnheader}>{props.values.Fett.Fettgramm}</ValueTd>
                    {props.values.Fett.Fettgramm1 && <ValueTd threeColumns={props.values.Columnheader}>{props.values.Fett.Fettgramm1}</ValueTd>}
                    {props.values.Fett.Fettgramm2 && <ValueTd threeColumns={props.values.Columnheader}>{props.values.Fett.Fettgramm2}</ValueTd>}
                </Tr>
                <Trpadding>
                    <FirstTd>{props.values.Fettsaeuren.Fettsaeurentitle}</FirstTd>
                    <ValueTd threeColumns={props.values.Columnheader}>{props.values.Fettsaeuren.Fettsaeurengramm}</ValueTd>
                    {props.values.Fettsaeuren.Fettsaeurengramm1 && <ValueTd threeColumns={props.values.Columnheader}>{props.values.Fettsaeuren.Fettsaeurengramm1}</ValueTd>}
                    {props.values.Fettsaeuren.Fettsaeurengramm2 && <ValueTd threeColumns={props.values.Columnheader}>{props.values.Fettsaeuren.Fettsaeurengramm2}</ValueTd>}
                </Trpadding>
                <Tr>
                    <FirstTd>{props.values.Kohlenhydrate.Kohlenhydratetitle}</FirstTd>
                    <ValueTd threeColumns={props.values.Columnheader}>{props.values.Kohlenhydrate.Kohlenhydrategramm}</ValueTd>
                    {props.values.Kohlenhydrate.Kohlenhydrategramm1 && <ValueTd threeColumns={props.values.Columnheader}>{props.values.Kohlenhydrate.Kohlenhydrategramm1}</ValueTd>}
                    {props.values.Kohlenhydrate.Kohlenhydrategramm2 && <ValueTd threeColumns={props.values.Columnheader}>{props.values.Kohlenhydrate.Kohlenhydrategramm2}</ValueTd>}
                </Tr>
                <Trpadding>
                    <FirstTd>{props.values.Zucker.Zuckertitle}</FirstTd>
                    <ValueTd threeColumns={props.values.Columnheader}>{props.values.Zucker.Zuckergramm}</ValueTd>
                    {props.values.Zucker.Zuckergramm1 && <ValueTd threeColumns={props.values.Columnheader}>{props.values.Zucker.Zuckergramm1}</ValueTd>}
                    {props.values.Zucker.Zuckergramm2 && <ValueTd threeColumns={props.values.Columnheader}>{props.values.Zucker.Zuckergramm2}</ValueTd>}
                </Trpadding>
                <Tr>
                    <FirstTd>{props.values.Eiweiss.Eiweisstitle}</FirstTd>
                    <ValueTd threeColumns={props.values.Columnheader}>{props.values.Eiweiss.Eiweissgramm}</ValueTd>
                    {props.values.Eiweiss.Eiweissgramm1 && <ValueTd threeColumns={props.values.Columnheader}>{props.values.Eiweiss.Eiweissgramm1}</ValueTd>}
                    {props.values.Eiweiss.Eiweissgramm2 && <ValueTd threeColumns={props.values.Columnheader}>{props.values.Eiweiss.Eiweissgramm2}</ValueTd>}
                </Tr>
                <Styledtr>
                    <FirstTd>{props.values.Salz.Salztitle}</FirstTd>
                    <ValueTd threeColumns={props.values.Columnheader}>{props.values.Salz.Salzgramm}</ValueTd>
                    {props.values.Salz.Salzgramm1 && <ValueTd threeColumns={props.values.Columnheader}>{props.values.Salz.Salzgramm1}</ValueTd>}
                    {props.values.Salz.Salzgramm2 && <ValueTd threeColumns={props.values.Columnheader}>{props.values.Salz.Salzgramm2}</ValueTd>}
                </Styledtr>
            </Table>
            <Text>{props.values.sternchentext}</Text>
        </TableWrapper>
    );
};

export default Naehrwerte;
