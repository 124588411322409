import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { mq } from 'helpers/stylehelpers';
import { colors } from 'helpers/variables';
import Img from 'gatsby-image';
import small from '../images/keyvisual/brotzeit-mobile.jpg';
import medium from '../images/keyvisual/brotzeit-tablet.jpg';
import big from '../images/keyvisual/brotzeit-desktop.jpg';
import smallgenuss from '../images/keyvisual/genuss-mobile.jpg';
import mediumgenuss from '../images/keyvisual/genuss-tablet.jpg';
import biggenuss from '../images/keyvisual/genuss-desktop.jpg';
import smalloriginal from '../images/keyvisual/original-mobile.jpg';
import mediumoriginal from '../images/keyvisual/original-tablet.jpg';
import bigoriginal from '../images/keyvisual/original-desktop.jpg';
import smallscheiben from '../images/keyvisual/scheiben-mobile.jpg';
import mediumscheiben from '../images/keyvisual/scheiben-tablet.jpg';
import bigscheiben from '../images/keyvisual/scheiben-desktop.jpg';

const Productkeyvisual = props => {
    const Styledlink = styled(Link)`
        color: ${colors.meinBayern.primary};
        position: absolute;
        top: 0;
        left: 0;
        padding: 10px 20px;
        font-weight: 700;
        text-decoration: none;
        z-index: 10;
    `;

    const Simg = styled.img`
        width: 100%;
        height: auto;
        display: block;
    `;

    const Keyvisual = styled.div`
        display: block;
        color: white;
        margin: 0;
        overflow: hidden;

        ${mq.large`
        color: white;
        position: relative;
        `};
    `;

    if (props.category === 'Für heißen Genuss') {
        return (
            <div>
                <Styledlink to="/#produkte"> ⬅ zurück</Styledlink>
                <Keyvisual>
                    <picture>
                        <source media="(min-width: 1280px)" srcSet={biggenuss} />
                        <source media="(min-width: 640px)" srcSet={mediumgenuss} />
                        <Simg src={smallgenuss} />
                    </picture>
                </Keyvisual>
            </div>
        );
    } else if (props.category === 'Bayerische Originale') {
        return (
            <div>
                <Styledlink to="/#produkte"> ⬅ zurück</Styledlink>
                <Keyvisual>
                    <picture>
                        <source media="(min-width: 1280px)" srcSet={bigoriginal} />
                        <source media="(min-width: 640px)" srcSet={mediumoriginal} />
                        <Simg src={smalloriginal} />
                    </picture>
                </Keyvisual>
            </div>
        );
    } else if (props.category === 'Leckere Brotzeit') {
        return (
            <div>
                <Styledlink to="/#produkte"> ⬅ zurück</Styledlink>
                <Keyvisual>
                    <picture>
                        <source media="(min-width: 1280px)" srcSet={big} />
                        <source media="(min-width: 640px)" srcSet={medium} />
                        <Simg src={small} />
                    </picture>
                </Keyvisual>
            </div>
        );
    } else {
        return (
            <div>
                <Styledlink to="/#produkte"> ⬅ zurück</Styledlink>
                <Keyvisual>
                    <picture>
                        <source media="(min-width: 1280px)" srcSet={bigscheiben} />
                        <source media="(min-width: 640px)" srcSet={mediumscheiben} />
                        <Simg src={smallscheiben} />
                    </picture>
                </Keyvisual>
            </div>
        );
    }
};
export default Productkeyvisual;
