import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { mq } from 'helpers/stylehelpers';

import Slider from 'ui-kit/slider';
import Section from 'ui-kit/section';
import Product from '../components/product';
import Container from 'ui-kit/container';
import { Headline, P } from 'ui-kit/typography';
import Intro from 'ui-kit/intro';

import Layout from '../components/layout';
import Naehrwerte from '../components/naehrwerte';
import Productkeyvisual from '../components/productkeyvisual';
import Img from 'gatsby-image';

const ProductPage = props => {
    const Imgwrapper = styled.div`
        margin: 0;
        max-width: 200px;

        ${mq.medium`
        max-width: 250px;
        margin: 0 auto;
    `};
        ${mq.large`
        max-width: 350px;
        margin: 0 auto;
    `};
    `;
    const ProductHeadline = styled.h1`
        color: #3155a1;
        font-weight: normal;
        margin: 0;
    `;

    const SubHead = styled.p`
        margin-top: 0px;
    `;

    const Button = styled.a`
        background-color: #3155a1;
        display: block;
        padding: 10px 0;
        text-align: center;
        color: white;
        border-radius: 30px;
        width: 100%;
        text-decoration: none;
        margin-top: 25px;

        ${mq.medium`
            display: inline-block;
            padding: 10px 30px;
            width: auto;
        `};
    `;

    const Colwrapper = styled.div`
        display: flex;
        justify-content: center;
    `;

    const Ingridents = styled.span`
        b {
            font-weight: 700;
        }
    `;

    return (
        <Layout
            title={`${
                props.data.productsJson.title
            } | Mein Bayern - voller Geschmack fast ohne Fett`}
        >
            <Productkeyvisual category={props.data.productsJson.category} />
            <Section>
                <Container size="l" grid layout="6-6" cols="2" alignItems="center" gap="s">
                    <div>
                        <Imgwrapper>
                            <Img
                                fluid={props.data.productsJson.src.childImageSharp.fluid}
                                alt="Gatsby Docs are awesome"
                            />
                        </Imgwrapper>
                    </div>
                    <Colwrapper>
                        <div>
                            <ProductHeadline>{props.data.productsJson.title}</ProductHeadline>
                            <SubHead>
                                {' '}
                                {props.data.productsJson.subtitle} {props.data.productsJson.weight}{' '}
                            </SubHead>
                            <p> {props.data.productsJson.description} </p>
                            {props.data.productsJson.footprint && (<p> {props.data.productsJson.footprint} </p>)}
                        </div>
                    </Colwrapper>
                </Container>
            </Section>
            <Section bg="gray">
                <Intro title="So leicht kann Genießen sein!" gap="xxxl">
                    <P fontSize="m">
                        <Ingridents dangerouslySetInnerHTML={{
                            __html: props.data.productsJson.ingredients
                        }} />
                    </P>
                    {props.data.productsJson.ingredients1 && <P fontSize="m">{props.data.productsJson.ingredients1}</P>}
                    {props.data.productsJson.ingredients2 && <P fontSize="m">{props.data.productsJson.ingredients2}</P>}
                </Intro>
                <Container size="l" grid layout="2-4-2" cols="3" alignItems="start" gap="s">
                    <div />
                    <Naehrwerte
                        headline="Durchschnittliche Nährwerte pro 100 g"
                        values={props.data.productsJson.prohundert}
                    />
                    <div />
                </Container>
            </Section>
            <Section>
                <Headline level="h2" gap="l" textAlign="center">
                    Ähnliche Produkte
                </Headline>
                <Slider>
                    {props.data.allProductsJson.edges.map(edge => {
                        return (
                            <Product
                                key={edge.node.title}
                                title={edge.node.title}
                                link={edge.node.slug}
                                category={edge.node.category}
                                src={edge.node.src.childImageSharp.fluid}
                            />
                        );
                    })}
                </Slider>
            </Section>
        </Layout>
    );
};

export const query = graphql`
    query($slug: String!, $category: String!) {
        allProductsJson(filter: { category: { eq: $category }, slug: { ne: $slug } }, limit: 8) {
            edges {
                node {
                    title
                    slug
                    category
                    src {
                        childImageSharp {
                            fluid(maxWidth: 250, quality: 80) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
        productsJson(slug: { eq: $slug }) {
            slug
            title
            id
            category
            weight
            description
            footprint
            ingredients
            ingredients1
            ingredients2
            subtitle
            link
            src {
                childImageSharp {
                    fluid(maxWidth: 400, quality: 80) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            allergene {
                type
                path {
                    childImageSharp {
                        fluid(maxWidth: 150, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            prohundert {
                Columnheader {
                    col1
                    col2
                    col3
                }
                Brennwert {
                    Brennwerttitle
                    Brennwertgramm
                    Brennwertgramm1
                    Brennwertgramm2
                }
                Fett {
                    Fetttitle
                    Fettgramm
                    Fettgramm1
                    Fettgramm2
                }
                Fettsaeuren {
                    Fettsaeurentitle
                    Fettsaeurengramm
                    Fettsaeurengramm1
                    Fettsaeurengramm2
                }
                Kohlenhydrate {
                    Kohlenhydratetitle
                    Kohlenhydrategramm
                    Kohlenhydrategramm1
                    Kohlenhydrategramm2
                }
                Zucker {
                    Zuckertitle
                    Zuckergramm
                    Zuckergramm1
                    Zuckergramm2
                }
                Eiweiss {
                    Eiweisstitle
                    Eiweissgramm
                    Eiweissgramm1
                    Eiweissgramm2
                }
                Salz {
                    Salztitle
                    Salzgramm
                    Salzgramm1
                    Salzgramm2
                }
            }
            proportion {
                PGramm
                Brennwert {
                    Brennwerttitle
                    Brennwertprozent
                }
                Fett {
                    Fetttitle
                    Fettprozent
                }
                Fettsaeuren {
                    Fettsaeurentitle
                    Fettsaeurenprozent
                }
                Kohlenhydrate {
                    Kohlenhydratetitle
                    Kohlenhydrateprozent
                }
                Zucker {
                    Zuckertitle
                    Zuckerprozent
                }
                Eiweiss {
                    Eiweisstitle
                    Eiweissprozent
                }
                Salz {
                    Salztitle
                    Salzprozent
                }
            }
        }
    }
`;

export default ProductPage;
